<template>
  <div class="inner-layout">

    <div class="page-title">마이페이지</div>

    <mypage-tab></mypage-tab>

    <div class="complete-msg">
      <img src="@/assets/svg/myPetComplete_cat.svg" v-if="getPet.code == 20">
      <img src="@/assets/svg/myPetComplete_dog.svg" v-if="getPet.code == 10">
      <h1><span class="color-1">{{this.getName}}</span>님의<br>반려동물 등록이 완료되었습니다.</h1>
      <p><span class="color-1">{{this.getPet.name}}</span>를 위한 <br>맞춤 콘텐츠와 서비스를 만나보세요😊</p>
      <router-link to="/">홈 바로가기</router-link>
      <router-link to="/mypet/">마이펫 바로가기</router-link>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "mypetList",
  data() {
    return {
      getPet :{
        code : 20, 
        name:'aa'
      },
    }
  },
  computed:{
    ...mapGetters({
      getName: 'session/getName',
      getPet: 'session/getPet',
    }),
  },
  methods: {
    mypet(){
      this.$router.push('/mypetRegister')
    },
  }
}
</script>
